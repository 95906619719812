<template>
  <div>
    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" dark>
      <span class="font-weight-bold">{{snackbar.text}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
            class="font-weight-bold"
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.status = false"
        >Fechar</v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogCadastro" persistent max-width="580" :fullscreen="$vuetify.breakpoint.width<450">
      <v-card>
        <v-card-title class="primary white--text pr-2">
          <span>Cadastro de Assinatura</span><v-spacer/>
          <v-btn color="white" icon @click="dialogCadastro=false; reiniciaEtapas(); listar(pgSelecionada)" class="mt-n1"><v-icon> mdi-close </v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-stepper v-model="step" vertical elevation="0" class="pb-3">
            <v-stepper-step
                :complete="step > 1"
                step="1"
                class="font-weight-bold"
            >
              Busca de colaborador
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card outlined>
                <v-form class="pa-5">
                  <v-row>
                    <v-col cols="12" class="pb-1">
                      <v-autocomplete
                          v-model="busca.idcolaborador"
                          :search-input.sync="search"
                          :items="colaboradores"
                          item-text="nome"
                          item-value="idcadciss"
                          label="Colaborador"
                          placeholder="Informe o código de cliente ou nome"
                          outlined
                          dense
                          clearable
                          hide-details
                          autofocus
                      >
                        <template v-slot:item="{ item }" >
                          <template v-if="$vuetify.breakpoint.smAndDown">
                            <span style="font-size: 0.7em; font-weight: 500">{{ item.nome | formataTextoPedidoConcreto }}</span>
                          </template>
                          <template v-else>
                            <span>{{ item.nome }}</span>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-btn class="primary" width="100%" :disabled="!busca.idcolaborador" @click="step=2" small elevation="0"><v-icon class="mr-2">mdi-magnify</v-icon> Buscar</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
                :complete="step > 2"
                step="2"
                class="font-weight-bold"
            >
              Dados do colaborador
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card outlined v-if="colaboradores">
                <v-form class="pa-5">
                  <v-row>
                    <v-col cols="12" class="pb-3" >
                      <strong>Nome:</strong> {{colaboradores[0].nome}} <br/>
                      <strong>CPF:</strong> {{colaboradores[0].cpf | formataCpf}} <br/>
                      <strong>Endereço:</strong> {{colaboradores[0].endereco}} <br/>
                      <strong>Cidade:</strong> {{colaboradores[0].cidade}}
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-text-field
                          v-model.number="cadastra.celular"
                          :rules="validarTelefone"
                          label="Celular"
                          v-mask="'(##) #####-####'"
                          clearable
                          dense
                          clear-icon="mdi-close"
                          outlined
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-text-field
                          v-model="cadastra.email"
                          :rules="validarEmail"
                          label="Email"
                          required
                          dense
                          clearable
                          clear-icon="mdi-close"
                          outlined
                          class="mb-n1"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-2 pr-3 pr-sm-2">
                      <v-btn small color="primary" width="100%" elevation="0" @click="step=1">
                        <v-icon small class="mr-2">mdi-arrow-u-up-left-bold</v-icon>
                        Voltar
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0 pt-sm-2 pl-3 pl-sm-2">
                      <v-btn small class="success" width="100%" elevation="0" :disabled="!verificaForm()" @click="gerarLink()">
                        <v-icon small class="mr-2">mdi-play</v-icon>
                        Gerar Link
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
                :complete="step > 3"
                step="3"
                class="font-weight-bold mb-4"
            >
              Link de assinatura
            </v-stepper-step>
            <v-stepper-content step="3" class="pa-0 mx-0 font-weight-bold">
              <v-card-subtitle class="px-6 py-0">
                <div class="red--text text-caption">
                  <strong>Aviso Importante:</strong> *Não compartilhe o link com ninguém além do assinante autorizado.</div>
              </v-card-subtitle>
              <v-card-text class="pa-5 pt-4" v-if="colaboradores && link">
                <v-text-field
                    :label="'Link: ' + (colaboradores[0].nome)"
                    ref="copiar"
                    dense
                    :value="link"
                    outlined
                    color="primary"
                    hide-details
                    readonly
                >
                  <template v-slot:append>
                    <v-btn small color="primary" icon :loading="carregando" @click="copy(link, 'copiar')">
                      <v-icon size="18">mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn class="mr-n1" small color="primary" icon :loading="carregando" @click="gerarQRCode(link,'Colaborador(a):\n' + (colaboradores[0].nome))">
                      <v-icon size="18">mdi-qrcode-scan</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-card-text>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog >
    <v-dialog v-model="dialogAlteracao" persistent max-width="580">
      <v-card v-if="assinaturaSelecionada">
        <v-card-title class="primary white--text">
          <span>Dados do Cadastro</span><v-spacer/>
          <v-btn color="white" icon @click="dialogAlteracao=false; reiniciaEtapas(); listar(pgSelecionada)" class="mt-n1 mr-n3"><v-icon> mdi-close </v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-1">
          <v-col cols="12" class="px-0">
            <strong>Filial:</strong> {{assinaturaSelecionada.idfilial}}-{{assinaturaSelecionada.filial}} <br/>
            <strong>Nome:</strong> {{assinaturaSelecionada.nome}} <br/>
            <strong>Data de Cadastro:</strong> {{assinaturaSelecionada.dtcadastro | formataData}} <br/>
          </v-col>
          <v-col cols="12" class="px-0 pt-0">
            <v-text-field
                v-model.number="cadastra.celular"
                :rules="validarTelefone"
                label="Celular"
                v-mask="'(##) #####-####'"
                clearable
                dense
                clear-icon="mdi-close"
                outlined
                hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="px-0 pt-0">
            <v-text-field
                v-model="cadastra.email"
                :rules="validarEmail"
                label="Email"
                required
                dense
                clearable
                clear-icon="mdi-close"
                outlined
                class="mb-n1"
                hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-card-text>
        <template v-if="assinaturaSelecionada.assinatura === 'P'">
          <v-card outlined class="mx-6 mt-0 mb-4">
            <v-card-subtitle class="pt-1 pb-2 px-3 red--text text-caption">
              Assinatura Pendente
            </v-card-subtitle>
            <v-card-text class="text-center px-3 pb-3">
              <v-row>
                <v-col class="pr-3 pb-0 pr-sm-1">
                  <v-btn width="100%" outlined color="primary" small elevation="0" @click="copy(`https://contrato.comercialivaipora.com.br/cadastrar/${assinaturaSelecionada.hash}`)">
                    <v-icon small class="mr-1">mdi-link-variant</v-icon> Copiar Link
                  </v-btn>
                </v-col>
                <v-col class="pl-3 pl-sm-1">
                  <v-btn width="100%" outlined color="primary" small elevation="0" @click="gerarQRCode(`https://contrato.comercialivaipora.com.br/cadastrar/${assinaturaSelecionada.hash}`,'Colaborador(a):\n' + assinaturaSelecionada.nome)">
                    <v-icon small class="mr-1">mdi-qrcode-scan</v-icon> Gerar QRCode
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <v-card outlined class="mx-6 mt-0 mb-4">
            <v-card-subtitle class="pt-1 pb-4 px-3 text-caption">
              Assinatura
              <v-btn icon x-small class="float-right mr-n1" color="red" @click="deleteImgAssinatura"><v-icon>mdi-delete</v-icon></v-btn>
            </v-card-subtitle>
            <v-card-text>
              <v-img class="mx-auto" max-height="100px" max-width="220px" :src="assinaturaSelecionada.assinatura"/>
            </v-card-text>
          </v-card>
        </template>
        <v-divider/>
        <v-card-actions v-if="cadastra.email !== assinaturaSelecionada.email || cadastra.celular !== assinaturaSelecionada.celular">
          <v-btn :disabled="!verificaForm()" small color="primary" elevation="0" class="px-10" @click="alteraDadosAssinatura()" width="100%"><v-icon small class="mr-2">mdi-content-save</v-icon> Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog >
    <v-dialog v-model="dialogExclusao" persistent max-width="580">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="mx-auto">Exclusão de cadastro</span>
        </v-card-title>
        <v-divider />
        <div class="text-center py-3">
          <v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
        </div>
        <v-card-subtitle class="text-center">
          <strong>Deseja realmente excluir o seguinte cadastro de assinatura? </strong> <br/>
        </v-card-subtitle>
        <v-card-text v-if="assinaturaSelecionada">
          <v-col cols="12">
            <strong>Filial:</strong> {{assinaturaSelecionada.idfilial}}-{{assinaturaSelecionada.filial}} <br/>
            <strong>Nome:</strong> {{ assinaturaSelecionada.idcadciss }}-{{ assinaturaSelecionada.nome | formataTextoNomeVendedores }}<br/>
            <strong>Email:</strong> {{assinaturaSelecionada.email}} <br/>
            <strong>Celular:</strong> {{assinaturaSelecionada.celular}} <br/>
            <strong>Data de Cadastro:</strong> {{assinaturaSelecionada.dtcadastro | formataData}} <br/>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3 py-2">
          <v-spacer />
          <v-btn small color="primary" text elevation="0" class="px-5" dark @click="dialogExclusao = false; assinaturaSelecionada=null; reiniciaEtapas()" width="49%">Cancelar</v-btn>
          <v-btn small color="error" elevation="0" class="px-10" @click="deleteAssinatura()" width="49%">Confirmar</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog >
    <v-dialog v-model="dialogQrcode" max-width="500" width="100%" scrollable>
      <v-card>
        <v-card-title class="primary white--text">
          <v-row no-gutters>
            <v-col cols="12">
              <div class="d-flex">
                <span>Acesso de link via QR Code</span>
                <v-spacer/>
                <v-btn color="white" icon @click="closeDialogQrcode()" class="mt-n1 mr-n3"> <v-icon> mdi-close </v-icon> </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <p class="font-weight-bold text-subtitle-2 mb-0">{{labelSubtitulo}}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider/>
        <v-card-text class="text-center pa-10">
          <v-container v-show="carregandoQrcode" max-width="480" width="100%">
            <v-progress-circular class="my-16 y-16" size="200" color="black" width="6" indeterminate></v-progress-circular>
          </v-container>
          <v-dialog-transition leave-absolute>
            <canvas
                v-show="!carregandoQrcode"
                style="border: 6px solid black; width: 100%; max-width: 400px; height: auto;"
                ref="qrcodeCanvas"
            />
          </v-dialog-transition>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col class="d-none d-md-block pr-1">
            <InputDatePicker
                :disabled="carregando"
                v-model="busca.dtinicio"
                label="Data inicial"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-col class="d-none d-md-block px-1">
            <InputDatePicker
                :disabled="carregando"
                v-model="busca.dtfim"
                label="Data final"
                :outlined="true"
                :dense="true"
            />
          </v-col>
          <v-col class="d-none d-md-block px-1">
            <v-select
                :items="filiaisFiltro"
                item-text="filial"
                item-value="idfilial"
                label="Filial"
                outlined
                dense
                :disabled="carregando"
                v-model="busca.idfilial"
                hide-details
            ></v-select>
          </v-col>
          <v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
            <v-sheet class="text-center" height="100%">
              <v-row class="pa-3">
                <v-col cols="6" class="mb-n3 px-2">
                  <InputDatePicker
                      :disabled="carregando"
                      v-model="busca.dtinicio"
                      label="Data inicial"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>
                <v-col cols="6" class="mb-n3 px-2">
                  <InputDatePicker
                      :disabled="carregando"
                      v-model="busca.dtfim"
                      label="Data final"
                      :outlined="true"
                      :dense="true"
                  />
                </v-col>
                <v-col cols="6" class="mb-n3 px-2">
                  <v-select
                      hide-details
                      :items="filiaisFiltro"
                      item-text="filial"
                      item-value="idfilial"
                      label="Filial"
                      outlined
                      dense
                      :disabled="carregando"
                      v-model="busca.idfilial"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="mb-n5 px-2">
                  <v-text-field
                      placeholder="Informe o código do cliente ou número do pedido"
                      :disabled="carregando"
                      v-model="busca.busca"
                      label="Buscar"
                      outlined
                      dense
                      @keydown.enter="listar(0)"
                  />
                </v-col>
                <v-col cols="6">
                  <v-btn width="100%" text class="mt-n3" color="error" @click="sheet = !sheet">Cancelar</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn width="100%" class="mt-n3" color="primary" @click="listar(0); sheet = !sheet">Filtrar</v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-bottom-sheet>
          <v-col cols="4" class="pl-1">
            <v-text-field
                class="d-flex d-md-none"
                :disabled="carregando"
                v-model="busca.busca"
                label="Buscar"
                placeholder="Informe o código do cliente ou número do pedido"
                outlined
                dense
                @keydown.enter="listar(0)"
                append-outer-icon="mdi-menu"
                @click:append-outer="sheet = !sheet"
                hide-details
            />
            <v-text-field
                class="d-none d-md-block"
                :disabled="carregando"
                v-model="busca.busca"
                label="Buscar"
                placeholder="Informe o código do cliente ou número do pedido"
                outlined
                dense
                @keydown.enter="listar(0)"
                hide-details
            />
          </v-col>
          <v-col cols="auto" class="ml-2">
            <v-btn
                :disabled="carregando"
                class="mt-1"
                color="primary"
                elevation="0"
                fab
                x-small
                @click="listar(0)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-title>
        Histórico de logs <v-spacer/>
<!--        <v-btn class="primary px-4" :loading="carregando" @click="dialogCadastro=true" small elevation="0">-->
<!--          <v-icon class="ma-0 mr-md-2" small>mdi-eye</v-icon>-->
<!--          {{ $vuetify.breakpoint.smAndDown ? '' : 'Backup de Contratos' }}-->
<!--        </v-btn>-->
      </v-card-title>
      <v-divider/>
      <v-progress-linear small indeterminate absolute :active="carregando" />
      <v-card-text class="pt-0 px-0" v-if="$vuetify.breakpoint.mdAndUp">
        <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Filial</th>
                <th class="text-left">Documento</th>
                <th class="text-left">Cliente</th>
                <th class="text-left">E-mail</th>
                <th class="text-left">Data do Documento</th>
                <th class="text-center">Logs</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="dadosListaAssinaturas">
                <tr v-for="(u, i) in dadosListaAssinaturas" :key="i">
                  <td style="font-size: 0.9em !important;"> {{ u.filial }} </td>
                  <td style="font-size: 0.9em !important;"> {{ u.idcadciss }}-{{ u.nome | formataTextoNomeVendedores }} </td>
                  <td class="text-truncate" style="font-size: 0.9em !important;"> {{ u.email }} </td>
                  <td class="text-truncate" style="font-size: 0.9em !important;"> {{ u.celular }} </td>
                  <td class="text-center font-weight-medium px-0" style="font-size: 0.8em !important;height:70px">
                    <div v-if="u.assinatura === 'P'" class="red--text"> Assinatura Pendente</div>
                    <v-img v-else class="mx-auto" max-height="60px" max-width="140px" :src="u.assinatura"/>
                  </td>
                  <td class="text-center" width="40px">
                    <v-btn x-small rounded color="primary" elevation="0" @click="buscaDelalhesProdutos(u)">
                      <v-icon small class="mr-1">mdi-eye</v-icon> Visualizar
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="text-center" colspan="6">Nenhum registro encontrado.</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-progress-linear indeterminate absolute :active="carregando" />
    </v-card>
    <v-card v-if="$vuetify.breakpoint.width<960" elevation="0" flat style="background-color: transparent !important;" class="mt-3">
      <v-container>
        <v-card-text class="elevation-0 pa-0">
          <v-row v-for="(item, index) in dadosListaAssinaturas" :key="index" class="mb-4">
            <v-col cols="12" class="pa-0">
              <v-card>
                <v-card-subtitle class="px-3 py-2">
                  <strong> Cliente: </strong> {{item.idcadciss}}-{{ item.nome }} <br/>
                  <strong> Filial: </strong> {{ item.filial }} <br/>
                  <strong> E-mail: </strong> {{ item.email }}<br/>
                  <strong> Celular: </strong> {{ item.celular }}<br/>
                  <strong> Status de Cadastro: </strong> {{ item.assinatura === 'P' ? 'Pendente' : 'Assinado' }}
                </v-card-subtitle>
                <v-divider />
                <v-card-actions class="pt-1 px-0">
                  <v-col class="py-0 py-sm-1">
                    <v-row no-gutters>
                      <v-col cols="6" class="pr-1 text-center">
                        <v-btn elevation="0" rounded max-width="250px" width="98%" x-small color="primary" @click="dialogAlteracao=true; assinaturaSelecionada=item; cadastra=JSON.parse(JSON.stringify(item))">
                          <v-icon small class="mr-2">mdi-eye-settings</v-icon>Visualizar
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="pl-1 text-center">
                        <v-btn elevation="0" rounded max-width="250px" width="98%" x-small color="error" @click="dialogExclusao=true; assinaturaSelecionada=item">
                          <v-icon small class="mr-1">mdi-delete</v-icon>Excluir
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import QRCode from "qrcode";
import InputDatePicker from "@/Components/Widgets/InputDatePicker.vue";

export default {
	name: "ContratosalvarCadastro",
  components: {InputDatePicker},
	mixins: [mixinFilial],
	data: () => ({
    sheet:false,

    colaboradores: null,
    link:null,

    snackbar: { status: false, timeout: 10000, text: 'Erro: tente recarregar a pagina!' , color: 'red'},

    carregando: false,
    carregandoSkeleton: false,
    carregandoQrcode: false,

    dadosListaAssinaturas: null,

    search: null,

		busca: {
      idfilial: null,
      busca: null,
      dtinicio: `${new Date().getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-01`,
      dtfim: `${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getDate()}`,
		},

    ultimaBusca: {
      idfilial: null,
    },

    cadastra: {
      email: null,
      celular: null,
    },

    dialogCadastro: false,
    dialogAlteracao: false,
    dialogExclusao: false,
    dialogQrcode: false,
    step:1,

    pgSelecionada: 0,
    assinaturaSelecionada: null,
    labelSubtitulo:null,

    validarTelefone: [
      value => {
        if (!value) return 'Campo obrigatório.';
        if (value.length >= 15) return true
        return 'O número informado deve ser válido.';
      },
    ],
    validarEmail: [
      value => {
        if (!value) return 'Campo obrigatório.';
        if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) return true
        return 'O e-mail informado deve ser válido.'
      },
    ],
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial !== 4 &&
						v.idfilial !== 6 &&
						v.idfilial !== 12 &&
						v.idfilial !== 13 &&
						v.idfilial !== 25 &&
						v.idfilial !== 28 &&
						v.idfilial !== 29 &&
						v.idfilial !== 33 &&
						v.idfilial !== 37 &&
						v.idfilial !== 39
					);
				});
			} else {
				return [];
			}
		},
	},
  watch: {
    search(val) {
      this.pesquisaColaboradores(val);
    },
  },
	methods: {
    listar(pg) {
      this.carregando = true;
      this.pgSelecionada = pg;
      this.ultimaBusca = this.busca;

      return axios
          .post(`${this.backendUrl}contrato/assinatura/lista`, {
            idfilial: this.busca.idfilial || null,
            limit: this.pgLimit,
            offset: this.pgLimit * pg,
          })
          .then((res) => {
            this.dadosListaAssinaturas = res.data.lista;
          })
          .catch((e) => {
            this.dadosListaAssinaturas = null;
            this.snackbar.color = 'red';
            if (e.response.data.erro) {
              this.snackbar.text = `${ e.response.data.erro } :[listar]`;
            } else {
              this.snackbar.text = 'Erro, tente recarregar a pagina! :[listar]'
            }

            this.snackbar.status = true;
          })
          .finally(() => {
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
    },

    pesquisaColaboradores(val) {
      if (!val || val.length < 2) {
        this.colaboradores = null;
        return;
      }
      return axios
            .post(`${this.backendUrl}contrato/colaborador/lista`, {
              limit: 30,
              offset: 0,
              busca: val.nome || val || null,
              idfilial: this.busca.idfilial || null,
            })
            .then((res) => {
              if (res.data) {
                this.colaboradores = res.data.map((v) => {
                  return {
                    ...v,
                    nome: `${v.idcadciss} - ${v.nome}`,
                  };
                })
              }
            })
            .catch((e) => {
              this.snackbar.color = 'red';
              if (e.response.data.erro) {
                this.snackbar.text = `${ e.response.data.erro } :[pesquisaColaboradores]`;
              } else {
                this.snackbar.text = 'Erro, tente recarregar a pagina! :[pesquisaColaboradores]'
              }
              this.snackbar.status = true;
            })
            .finally(() => {
              this.carregando = false;
            });
    },

    gerarLink(){
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}contrato/assinatura/cadastra`, {
            idfilial: this.ultimaBusca.idfilial,
            idcadciss: this.colaboradores[0].idcadciss,
            idcadsenior: this.colaboradores[0].idcadsenior,
            email: this.cadastra.email,
            celular: this.cadastra.celular,
          })
          .then((res) => {
            if (res.data.retorno) {
              this.listar(0);
              this.step=3;
              this.link=res.data.link;
              this.cadastra={
                email: null,
                celular: null,
              };
            }
          })
          .catch((e) => {
            this.snackbar.color = 'red';
            if (e.response.data.erro) {
              this.snackbar.text = `${ e.response.data.erro } :[pesquisaColaboradores]`;
            } else {
              this.snackbar.text = 'Erro, tente recarregar a pagina! :[pesquisaColaboradores]'
            }
            this.snackbar.status = true;
          })
          .finally(() => {
            this.carregando = false;
          });
    },

    reiniciaEtapas(){
      this.colaboradores=null;
      this.step=1;
      this.link=null
      this.cadastra={
        email: null,
        celular: null,
      };
    },

    alteraDadosAssinatura(){
      return axios
          .post(`${this.backendUrl}contrato/assinatura/altera`, {
            idfilial: this.assinaturaSelecionada.idfilial,
            idcadciss: this.assinaturaSelecionada.idcadciss,
            email: this.cadastra.email,
            celular: this.cadastra.celular,
          })
          .then(() => {
            this.snackbar.color = 'success';
            this.snackbar.text = 'Cadastro atualizado com sucesso!'
            this.snackbar.status = true;
          })
          .catch((e) => {
            this.snackbar.color = 'red';
            if (e.response.data.erro) {
              this.snackbar.text = `${ e.response.data.erro } :[alteraDadosAssinatura]`;
            } else {
              this.snackbar.text = 'Erro, tente recarregar a pagina! :[alteraDadosAssinatura]'
            }
            this.snackbar.status = true;
          })
          .finally(() => {
            this.dialogAlteracao=false;
            this.listar(0);
            this.carregando = false;
            this.carregandoSkeleton = false;
            setTimeout(() => {
              this.reiniciaEtapas();
            },500)

          });
    },

    deleteAssinatura(){
      return axios
          .post(`${this.backendUrl}contrato/assinatura/delete`, {
            idfilial: this.assinaturaSelecionada.idfilial,
            idcliente: this.assinaturaSelecionada.idcadciss,
          })
          .then(() => {
            this.snackbar.color = 'success';
            this.snackbar.text = 'Cadastro excluído com sucesso!'
            this.snackbar.status = true;
          })
          .catch((e) => {
            this.snackbar.color = 'red';
            if (e.response.data.erro) {
              this.snackbar.text = `${ e.response.data.erro } :[deleteAssinatura]`;
            } else {
              this.snackbar.text = 'Erro, tente recarregar a pagina! :[deleteAssinatura]'
            }
            this.snackbar.status = true;
          })
          .finally(() => {
            this.listar(0);
            this.carregando = false;
            this.carregandoSkeleton = false;
            this.dialogExclusao=false;
          });

    },

    deleteImgAssinatura(){
      return axios
          .post(`${this.backendUrl}contrato/assinatura/delete`, {
            idfilial: this.assinaturaSelecionada.idfilial,
            idcliente: this.assinaturaSelecionada.idcadciss,
            deleteimagem: true,
          })
          .then(() => {
            this.snackbar.color = 'success';
            this.snackbar.text = 'Assinatura excluída com sucesso!'
            this.snackbar.status = true;
            this.assinaturaSelecionada.assinatura = 'P';
          })
          .catch((e) => {
            this.snackbar.color = 'red';
            if (e.response.data.erro) {
              this.snackbar.text = `${ e.response.data.erro } :[deleteImgAssinatura]`;
            } else {
              this.snackbar.text = 'Erro, tente recarregar a pagina! :[deleteImgAssinatura]'
            }
            this.snackbar.status = true;
          })
          .finally(() => {
            this.listar(0);
            this.carregando = false;
            this.carregandoSkeleton = false;
            this.dialogExclusao=false;
          });

    },

    copy(link, inputN) {
      if (inputN) {
        var copyTextarea = this.$refs[inputN].$refs.input;
        copyTextarea.select();
        try {
          var successful = document.execCommand("copy");
          var msg = successful ? "sim!" : "não!";
          if (successful) {
            msg = `O link foi copiado para sua área de transferência`;
          } else {
            msg = "Não foi possível copiar o texto, use o atalho Crtl+C.";
          }
          this.snackbar.color = 'success';
          this.snackbar.text = msg;
          this.snackbar.status = true;
        } catch (err) {
          this.snackbar.text = "Não foi possível copiar o texto, use o atalho Crtl+C.";
          this.snackbar.status = true;
        }
      } else {
        navigator.clipboard.writeText(link).then(() => {
          this.snackbar.color = 'success';
          this.snackbar.text = `O link foi copiado para sua área de transferência`;
          this.snackbar.status = true;
        }).catch(() => {
          this.snackbar.text = "Não foi possível copiar o texto, use o atalho Crtl+C.";
          this.snackbar.status = true;
        });
      }
    },

    gerarQRCode(link, label) {
      this.carregandoQrcode=true;
      this.labelSubtitulo=label;
      this.dialogQrcode=true;
      // Gera o QR Code e o renderiza no canvas
      setTimeout(() => {
        QRCode.toCanvas(this.$refs.qrcodeCanvas, link,{ width: 400 }, () => {})
        this.carregandoQrcode=false;
      },1000)
    },

    closeDialogQrcode(){
      this.dialogQrcode=false;

      setTimeout(() => {
        this.labelSubtitulo=null;
        const canvas = this.$refs.qrcodeCanvas
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
      },500)
    },

    verificaForm(){
      if(this.cadastra.celular != null && this.cadastra.celular.length >= 15 && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.cadastra.email) != false)){
        return true;
      }
    },

		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			await this.listar(0);
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>

::v-deep .v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0 !important;
}

</style>